@import "../../styles/text.scss";
@import "../../styles/theme.scss";
@import "../../styles/spacings.scss";

.activeBox {
    border: solid 1px $text-grey12;
    border-left: solid 5px $background-red;
    width: auto;
}

.companyIcon {
    position: absolute;
    @media (max-width: 991.98px) {
            bottom: 10px;
    }
    @media (max-width: 767.98px) {
        position: unset;
        bottom: 0px;
        margin-left: 0rem;
    }
}

.scrollDown {
    display: flex;
    justify-content: start;
    padding-top: 4rem;
    @media (max-width: 767.98px) {
        padding-top: 1rem;
        justify-content:center
    }
}

.homeBannerBrand {
    overflow: unset;

    img {
        width: 100px;
        object-fit: contain;
        margin-right: 20px;
    }

    @media (max-width: 767.98px) {
        overflow: scroll hidden;
        img{
            width: 5rem;
        }
    }
}